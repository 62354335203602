<template>
	<div class="notice_popup prj_itv pass">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<tec-summary tagNm="div" class="member" :value="data"/>
		<!-- <div class="tit" style="margin-left:150px;">참여기간 변경</div> -->
		<div class="tit">참여기간 변경</div>
		<div class="itv_date">
			<!-- <p style="margin-left:30px">참여기간</p><br/> -->
			<SelectDate tagNm="div" classY="wd01" class="select-box join" v-if="data.mberSeq" type="date" v-model="joinStartYyyymmdd" from="Y-3" :to="joinEndYyyymmdd || 'Y3'" rules="required">
				<template v-slot:after> 부터</template>
			</SelectDate>
			<SelectDate tagNm="div" classY="wd01" class="select-box join" v-if="data.mberSeq" type="date" v-model="joinEndYyyymmdd" :from="joinStartYyyymmdd || 'Y-3'" to="Y3" rules="required">
				<template v-slot:after> 까지</template>
			</SelectDate>
			<SelectComp tagName="div" class="select_join" type="radio" title="참여기간" list="N:'참여기간' 변경,Y:'중도철수'로 처리" v-model="withDrawal"/>
		</div>

		<!-- <div class="qs" style="margin-left:130px;">위의 내용으로 변경 하시겠습니까?</div> -->
		<div class="qs">위의 내용으로 변경 하시겠습니까?</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close', false)">취소</div>
			<div class="btn" @click="save">예</div>
		</div>
	</div>
</template>
<script>
import tecSummary from "@/components/highpro/TecSummary.vue";

export default {
	components: { tecSummary },
	props: { param:Object },
	data() {
		return {
			data: {},
			joinStartYyyymmdd: '',
			joinEndYyyymmdd: '',
			withDrawl : '',
		};
	},
	mounted(){
		var projectSeq = this.param.projectSeq;
		var tecMberSeq = this.param.tecMberSeq;
		this.$.httpPost('/api/prj/adm/getPrjMberInfo', {projectSeq, tecMberSeq})
			.then(res => {
				this.data = res.data;
				this.joinStartYyyymmdd = this.data.joinStartYyyymmdd;
				this.joinEndYyyymmdd   = this.data.joinEndYyyymmdd;
				this.$emit('updated');
			}).catch(this.$.httpErrorCommon);
	},
	methods: {
		save(){
			if(this.withDrawal == '' || this.withDrawal == null) {
				alert("변경 유형을 선택해 주세요.");
			} else {
				var interviewReqSeq = this.data.interviewReqSeq;
				var tecMberSeq		= this.param.tecMberSeq;
				var joinStartYyyymmdd	= this.joinStartYyyymmdd;
				var joinEndYyyymmdd		= this.joinEndYyyymmdd;
				var withDrawal = this.withDrawal;
				this.$.httpPost('/api/prj/adm/savePrjJoinTerm', {interviewReqSeq, tecMberSeq, joinStartYyyymmdd, joinEndYyyymmdd, withDrawal})
				.then(() => {
					alert('정보가 저장되었습니다.')
					this.$emit('close', true);
				}).catch(this.$.httpErrorCommon);
			}
			
		}
	},
};
</script>
